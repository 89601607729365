<template>
  <div>
    <!-- 레이블 -->
    <div class="sub body3-medium margin-bottom-12" v-if="label">{{ label }}</div>
    <!-- 인풋 -->
    <input class="input body3 sub4" type="text" :value="inputValue"
           :maxlength="maxLength" @keyup.enter="$emit('onEnter')" @blur="chkValid"
           :placeholder="placeholder" @input="e => onKeyup(e.target.value)" :readonly="disableStatus === true" />
    <!-- 에러메세지 -->
    <div v-if="errorMsg !== ''" class="lp-error-msg">{{ errorMsg }}</div>
  </div>
</template>

<script>
  import { isValid } from '@/utils/helper/form-helper'
  export default {
    props: {
      name: {
        type: String,
        required: false
      },
      label: {
        type: String,
        required: false
      },
      placeholder: {
        type: String,
        required: false
      },
      validate: {
        type: Object,
        required: false
      },
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    created() {
      if (this.value) {
        this.inputValue = this.value;
      }
    },

    mounted() {
      if (this.validate) {
        this.valid = this.validate;
      }
      this.disableStatus = this.disabled;
    },

    data() {
      return {
        disableStatus: false,
        inputValue: '',
        errorMsg: '',
        keyup: false,
        valid: {
          valid_required: false,
          error_msg: '',
          max_length: 100,
          min_length: 0,
          alpha_s: true,
          alpha_g: true,
          special: true,
          num: true
        },
        minMsg: ''
      };
    },

    watch: {
      disabled(n) {
        this.disableStatus = n;
      },
      value(n) {
        this.inputValue = n;
      }
    },

    computed: {
      maxLength() {
        if (this.validate)
          return this.validate.max_length ? this.validate.max_length : 50;
        else
          return 50;
      }
    },

    methods: {
      onKeyup(value) {
        this.inputValue = value;
        this.$emit('update:value', this.inputValue);
        this.chkValid();
      },
      chkValid() {
        if (this.minMsg) this.errorMsg = this.minMsg;
        else this.errorMsg = '';
        // validation이 필수가 아닌 경우
        if (!this.validate || !this.validate.valid_required) return;

        let props = {
          value: this.inputValue,
          type: this.type,
          validate: this.validate,
          component_name: 'c-input-text'
        }

        this.errorMsg = isValid(props, this.name);
        this.validate.error_msg = this.errorMsg;
      },
      clear() {
        this.inputValue = '';
        this.$emit('update:value', this.inputValue);
      }

    }
  }
</script>

<style lang="stylus">
  .input
    font-family inherit
  .input::placeholder
    font-size inherit
    font-weight 400
    color #bdbdbd
</style>

<style lang="stylus" type="stylus" scoped>
  .number-wrapper
    display flex
    flex-direction column
  .c-input-text-container
    width 100%

  .label
    display block

  input
    border 1px solid #e3e3e3
    border-radius 8px
    padding 12px 16px
    font-size 15px
    background-color white
    -webkit-appearance 'none'
    font-family inherit

  textarea:focus
  input:focus
    outline none

  .lp-error-msg
    color #e80000
    font-size 0.7rem
    margin-top 4px
    margin-bottom 12px
    width: 100%
  textarea
    width 100%
    height 100%
    overflow hidden
    border 0
    resize none

  .input-disabled
    background-color #eee
    color #777
  /*visibility hidden*/

</style>
